import React from 'react'
import SliderData from './SliderData'

function ImageSlider() {
    return ( 
        <>
        
        <SliderData/>
        
        </>
    )
}

export default ImageSlider