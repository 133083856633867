import React, {useState,} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';
import ImageSlider from './ImageSlider';
import SliderData from './SliderData';



function Navbar() {

    const [click, setClick] = useState(false);


    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)


    return (
        <>
<nav className='navbar'>
    <div className='navbar-container'>
        <Link to='/' className='navbar-logo'onClick={closeMobileMenu}>
            <img src='/images/logoe_t-removebg-preview.png' alt='logo'></img>
        </Link>
        <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times': 'fas fa-bars'}/>
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li className = 'nav-item' >
        
        <Link to = '/project' className = 'nav-links' onClick = {closeMobileMenu}>
                Projects 
                </Link> 
                </li> 
                <li className = 'nav-item'>
               
                <Link to = '/about' className = 'nav-links' onClick = {closeMobileMenu}>
                About 
                </Link> 
                </li> 
                <li className = 'nav-item'>
                
                <Link to = '/contact' className = 'nav-links' onClick = {closeMobileMenu}>
                Contact
                </Link> 
                </li>
        </ul>
    </div>
    <ImageSlider slides = {SliderData}/> 
</nav>


       
        </>
    )
}

export default Navbar